import apiClient from '@/services/axios'

const END_POINT = '/ortb/stats/dashboard'

/**
 * Get ads
 *
 * @param {int} id - User ID
 * @returns {Promise} - Request promise
 */
export async function getDashboardStats() {
  return apiClient.get(END_POINT).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
