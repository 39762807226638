<template>
  <div class="card">
    <div class="card-body position-relative overflow-hidden text-center">
      <div class="font-size-36 font-weight-bold text-dark mb-n2">{{ value }}</div>
      <div class="text-uppercase" :style="colorStyle">{{ label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dashboard-card',
  props: {
    label: String,
    value: String,
    color: String,
  },
  data() {
    return {
      colorStyle: 'color:' + this.color,
    }
  },
  mounted() {
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
